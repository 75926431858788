.install-btn {
  background: #028760;
  border-radius: 8px;
  margin-bottom: 80px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    animation: shiny-btn-anim 5s ease-in-out infinite;
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: -180px;
    width: 30px;
  }

  @keyframes shiny-btn-anim {
    0% {
      opacity: 0;
      transform: scale(0) rotate(45deg)
    }

    80% {
      opacity: .5;
      transform: scale(0) rotate(45deg)
    }

    81% {
      opacity: 1;
      transform: scale(4) rotate(45deg)
    }

    to {
      opacity: 0;
      transform: scale(50) rotate(45deg)
    }
  }
}

.installing {
  // button is disabled
  opacity: 0.4;
  cursor: not-allowed;
}

.btn-view {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  line-height: 21px;
  height: 45px;
}