.container {
  width: 100vh;
  height: 100vh;
  display: flex;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

