.container {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 1fr;
}

.total-evaluate {
  padding-bottom: 16px;
  column-gap: 24px;
  display: grid;
  flex-grow: 1;
  grid-template-columns: max-content auto;

  .evaluate-number {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-family: "Google Sans Display", Roboto, Arial, sans-serif;
    line-height: 4rem;
    font-size: 3.5rem;
    letter-spacing: 0;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  .evaluate-row {
    display: inline-block;

    span {
      height: 16px;
      width: 16px;
      display: inline-block;
      text-align: left;
    }
  }

  .per-star-row {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;

    .per-star-row-number {
      font-family: Roboto, Arial, sans-serif;
      line-height: 1rem;
      font-size: .75rem;
      letter-spacing: .025em;
      font-weight: 400;
      padding-right: 16px;
    }

    .per-star-row-progress {
      border-radius: 9999px;
      background-color: rgb(232, 234, 237);
      height: 10px;

      .progress-bar {
        background-color: #01875f;
        height: 100%;
        border-radius: inherit;
      }
    }
  }
}

.comment-row {
  padding: 16px 0;

  header {
    display: block;
  }
}

.avatar {
  display: grid;
  align-items: center;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, max-content);

  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    background-size: cover;
  }

  .name {
    color: rgb(32, 33, 36);
    fill: rgb(32, 33, 36);
    stop-color: rgb(32, 33, 36);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0142857143em;
    line-height: 1.25rem;
  }
}

.evaluate {
  margin-top: 16px;

  .evaluate-row {
    display: inline-block;

    span {
      height: 12px;
      width: 12px;
      display: inline-block;
      text-align: left;
    }
  }
}

.star {
  color: #01875f;
  fill: #01875f;
  stop-color: #01875f;

  &.disabled {
    fill: rgb(241, 243, 244);
    stop-color: rgb(241, 243, 244);
  }
}

.comment-content {
  margin-top: 8px;
  font-size: .875rem;
  letter-spacing: .0142857143em;
  line-height: 1.25rem;
  overflow-wrap: anywhere;
}