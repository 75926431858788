.title {
  color: #222;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}

.content {
  color: #666;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 19px;
  margin-bottom: 34px;
  word-break: break-all;
}