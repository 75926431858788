.header {
  align-items: center;
  display: flex;
  margin-bottom: 28px;
}

.loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  visibility: hidden;
  opacity: 0;

  &.running {
    visibility: visible;
    opacity: 1;
  }

  svg {
    animation: loading-rotate 2s linear infinite;
    color: #028760;
    display: block;
    height: 100%;
    width: 100%;

    circle {
      stroke: currentColor;
      stroke-width: 2px;
      stroke-linecap: round;
      animation: loading-circular 1.5s ease-in-out infinite;
    }
  }
}

.icon {
  height: 74px;
  justify-content: center;
  margin-right: 22px;
  position: relative;
  width: 74px;
  align-items: center;
  display: flex;
}

.icon-img {
  border-radius: 8px;
  display: block;
  height: 100%;
  overflow: hidden;
  transition: all .35s;
  width: 100%;

  &.installing {
    width: 34px;
    height: 34px;
  }
}

.info {
  flex: 1;
  overflow: hidden;
}

.info-title {
  color: #111;
  font-size: 23px;
  font-weight: 500;
  line-height: 33px;
  margin-bottom: 2px;
}

.info-description {
  color: #028760;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
}

.info-progress {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 3px;
}

.info-play {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 10px;
  font-weight: 400;
  height: 14px;
}

@keyframes loading-rotate {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(1turn)
  }
}

@keyframes loading-circular {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0
  }

  50% {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -40
  }

  to {
    stroke-dasharray: 90,150;
    stroke-dashoffset: -120
  }
}