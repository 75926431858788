.container {
  display: flex;
  margin-bottom: 38px;
}

.view {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &:not(:first-child) {
    &::before {
      background: #ebebeb;
      bottom: 0;
      content: "";
      height: 24px;
      left: 0;
      margin: auto 0;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }
}

.view-value {
  align-items: center;
  display: flex;
  font-weight: 600;
  margin-bottom: 5px;

  img {
    display: block;
    margin-left: 2px;
    width: 11px;
  }
}

.with-border {
  border: 1px solid #020202;
  border-radius: 2px;
  color: #020202;
  font-size: 10px;
  height: 16px;
  justify-content: center;
  line-height: 14px;
  min-width: 17px;
  padding: 0 2px;
}

.view-label {
  color: #5d5d5d;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

