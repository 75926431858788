.loader {
  position: fixed;
  z-index: 9999;

  top: calc(50% - 5vmax);
  left: calc(50% - 5vmax);

  width: 10vmax;
  height: 10vmax;
  border: 5px solid #9daed7;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.background-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;

  background-color: rgba(0, 0, 0, 0.5);
}