.container {
  margin-bottom: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  height: 324px;
  width: fit-content;
}

.view {
  border-radius: 8px;
  height: 324px;
  margin-right: 11px;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    width: auto;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
}